import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {
  Box,
  Paper,
  Grid,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Divider,
  Fade,
  Typography,
  useTheme,
} from '@mui/material';
import {
  SaveSharp as SaveSharpIcon,
  AddCircleSharp as AddCircleSharpIcon,
  RemoveCircleSharp as RemoveCircleSharpIcon,
  CancelSharp as CancelSharpIcon,
  FilterAltSharp as FilterAltSharpIcon,
  RecentActors as DecksIcon,
} from '@mui/icons-material';

import CustomizableHeader from "../../components/common/CustomizeableHeader";
import LoginPrompt from "../../components/decks/LoginPromt";
import { useCardService } from "../../services/cardService";
import { useDeckService } from "../../services/deckService";
import { useAuth } from "../../contexts/AuthContext";

const TRANSITION_DURATION = 300;

const Deckbuilding = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const cardService = useCardService();
  const deckService = useDeckService();
  const { user } = useAuth();
  const [cardData, setCardData] = useState([]);
  const [filteredCardData, setFilteredCardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedCards, setSelectedCards] = useState({});
  const [selectedCardCount, setSelectedCardCount] = useState(0);
  const [cardDialogOpen, setCardDialogOpen] = useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [deckName, setDeckName] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editDeckId, setEditDeckId] = useState(null);

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const response = await cardService.getCards();
        const cards = response.data.cards;
        setCardData(cards);
        setFilteredCardData(cards);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching card data:', error);
        setError(error.message);
        setLoading(false);
      }
    };
    fetchCardData();

    // Check if we're in edit mode
    if (location.state && location.state.deck) {
      const editDeck = location.state.deck;
      setEditMode(true);
      setEditDeckId(editDeck.id);
      setDeckName(editDeck.name);
      const selectedCardsObj = editDeck.cards.reduce((acc, card) => {
        acc[card.id] = true;
        return acc;
      }, {});
      setSelectedCards(selectedCardsObj);
      setSelectedCardCount(editDeck.cards.length);
    }
  }, []);

  const handleFinalSave = async () => {
    if (!deckName.trim()) {
      alert("Bitte geben Sie einen Decknamen ein.");
      return;
    }

    const selectedCardIds = Object.keys(selectedCards).filter(
      (id) => selectedCards[id]
    );

    try {
      let response;
      if (editMode) {
        response = await deckService.updateDeck(
          editDeckId,
          selectedCardIds,
          deckName
        );
      } else {
        response = await deckService.createDeck(selectedCardIds, deckName);
      }
      const data = response.data;
      alert(data.message);
      navigate('/user-decks');
    } catch (error) {
      console.error('Error saving deck:', error);
      alert("Deck konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.");
    }
  };

  const handleSaveClick = () => {
    const selectedCardIds = Object.keys(selectedCards).filter(
      (id) => selectedCards[id]
    );

    if (selectedCardIds.length !== 20) {
      alert("Sie müssen genau 20 Karten auswählen, um Ihr Deck zu speichern.");
      return;
    }

    setSaveDialogOpen(true);
  };

  const handleCardDialogOpen = (card) => {
    setSelectedCard(card);
    setCardDialogOpen(true);
  };

  const handleCardDialogClose = () => {
    setCardDialogOpen(false);
    // Clear the selected card after the fade-out animation
    setTimeout(() => setSelectedCard(null), TRANSITION_DURATION);
  };

  const handleFilterDialogOpen = () => {
    setFilterDialogOpen(true);
  };

  const handleFilterDialogClose = () => {
    setFilterDialogOpen(false);
  };

  const handleSort = (category) => {
    if (activeCategory === category) {
      setFilteredCardData(cardData);
      setActiveCategory(null);
    } else {
      const filtered = cardData.filter(
        (card) =>
          card.category.toLowerCase() === category.toLowerCase()
      );
      setFilteredCardData(filtered);
      setActiveCategory(category);
    }
    handleFilterDialogClose();
  };

  const handleCardSelection = (cardId, e) => {
    e.stopPropagation();
    setSelectedCards((prevSelected) => {
      const newSelected = {
        ...prevSelected,
        [cardId]: !prevSelected[cardId],
      };
      const newCount = Object.values(newSelected).filter(Boolean).length;
      setSelectedCardCount(newCount);
      return newSelected;
    });
  };

  const CardCounterContent = () => (
    <Typography
      sx={{
        color:
          selectedCardCount === 20
            ? theme.palette.success.main
            : theme.palette.error.main,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
      }}
    >
      {selectedCardCount}/20 Karten
    </Typography>
  );

  const renderCards = (cards) => (
    <Grid container spacing={2}>
      {cards.map((card, index) => (
        <Grid item xs={6} key={index}>
          <TallItem
            selected={selectedCards[card.id]}
            onClick={() => handleCardDialogOpen(card)}
          >
            <IconContainer>
              <IconButton
                onClick={(e) => handleCardSelection(card.id, e)}
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: 'x-large',
                  position: 'absolute',
                  right: 0,
                  padding: 0,
                  zIndex: 990,
                }}
              >
                {selectedCards[card.id] ? (
                  <RemoveCircleSharpIcon
                    sx={{
                      color: theme.palette.error.main,
                      fontSize: 'x-large',
                    }}
                  />
                ) : (
                  <AddCircleSharpIcon
                    sx={{
                      color: theme.palette.success.main,
                      fontSize: 'x-large',
                    }}
                  />
                )}
              </IconButton>
            </IconContainer>
            <TallItemContent>
              <CardTitle>{card.name}</CardTitle>
              <CardCategory>
                {card.category.charAt(0).toUpperCase() +
                  card.category.slice(1).toLowerCase()}
              </CardCategory>
            </TallItemContent>
          </TallItem>
        </Grid>
      ))}
    </Grid>
  );

  if (!user) {
    return <LoginPrompt />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Fehler: {error}</div>;
  }

  return (
    <>
      <CustomizableHeader
        leftIcon={<DecksIcon />}
        leftText={editMode ? 'Deck bearbeiten' : 'Deck erstellen'}
        rightContent={<CardCounterContent />}
      />
      <Box
        sx={{
          flexGrow: 1,
          p: theme.spacing(2),
          pb: theme.spacing(10),
          minHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Grid container spacing={2}>
          {/* Save and Abbrechen Buttons */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Item
                clickable
                onClick={() => setSaveDialogOpen(true)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                  marginRight: theme.spacing(1),
                  opacity: selectedCardCount === 20 ? 1 : 0.5,
                  pointerEvents: selectedCardCount === 20 ? 'auto' : 'none',
                }}
              >
                {editMode ? 'Deck aktualisieren' : 'Deck speichern'}
                <SaveSharpIcon sx={{ ml: 1 }} />
              </Item>

              <Item
                clickable
                onClick={() => navigate('/user-decks')}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                  marginLeft: theme.spacing(1),
                  backgroundColor: theme.palette.error.main,
                  color: theme.palette.getContrastText(theme.palette.error.main),
                  '&:hover': {
                    backgroundColor: theme.palette.error.dark,
                  },
                }}
              >
                Abbrechen
                <CancelSharpIcon sx={{ ml: 1 }} />
              </Item>
            </Box>
          </Grid>

          {/* Filter Button */}
          <Grid item xs={12}>
            <Item
              clickable
              onClick={handleFilterDialogOpen}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: theme.spacing(1),
              }}
            >
              Filter <FilterAltSharpIcon sx={{ ml: 1 }} />
            </Item>
          </Grid>

          {/* Divider Below Filter */}
          <Grid item xs={12}>
            <Divider
              sx={{
                backgroundColor: theme.palette.divider,
                height: '1px',
                width: '95%',
                margin: theme.spacing(2, 'auto'),
              }}
            />
          </Grid>
        </Grid>

        {renderCards(filteredCardData)}

        <StyledDialog
          open={cardDialogOpen}
          onClose={handleCardDialogClose}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: TRANSITION_DURATION }}
        >
          <Fade in={cardDialogOpen} timeout={TRANSITION_DURATION}>
            <div>
              <StyledDialogTitle
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>Karteninformation</span>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleCardDialogClose}
                  aria-label="close"
                >
                  <CancelSharpIcon />
                </IconButton>
              </StyledDialogTitle>
              <StyledDialogContent>
                {selectedCard && (
                  <>
                    <div>
                      <strong>Effekt:</strong> {selectedCard.effect}
                    </div>
                    <div>
                      <strong>Blockierte Züge:</strong>{' '}
                      {selectedCard.blocker}
                    </div>
                  </>
                )}
              </StyledDialogContent>
            </div>
          </Fade>
        </StyledDialog>

        <StyledDialog
          open={filterDialogOpen}
          onClose={handleFilterDialogClose}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: TRANSITION_DURATION }}
        >
          <Fade in={filterDialogOpen} timeout={TRANSITION_DURATION}>
            <div>
              <StyledDialogTitle
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>Filter</span>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleFilterDialogClose}
                  aria-label="close"
                >
                  <CancelSharpIcon />
                </IconButton>
              </StyledDialogTitle>
              <StyledDialogContent>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {[
                    'Angriff',
                    'Strategie',
                    'Verteidigung',
                    'Hybrid',
                    'Zauber',
                  ].map((category) => (
                    <Button
                      key={category}
                      onClick={() => handleSort(category)}
                      sx={{
                        color: theme.palette.text.primary,
                        mb: theme.spacing(1),
                        backgroundColor:
                          activeCategory === category
                            ? theme.palette.action.selected
                            : theme.palette.background.paper,
                        width: '100%',
                        fontFamily: theme.typography.fontFamily,
                        border: `1px solid ${theme.palette.divider}`,
                        textTransform: 'none',
                      }}
                    >
                      {category}
                    </Button>
                  ))}
                </Box>
              </StyledDialogContent>
            </div>
          </Fade>
        </StyledDialog>

        <StyledDialog
          open={saveDialogOpen}
          onClose={() => setSaveDialogOpen(false)}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: TRANSITION_DURATION }}
        >
          <Fade in={saveDialogOpen} timeout={TRANSITION_DURATION}>
            <div>
              <StyledDialogTitle
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>Deck-Name</span>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setSaveDialogOpen(false)}
                  aria-label="close"
                >
                  <CancelSharpIcon />
                </IconButton>
              </StyledDialogTitle>
              <StyledDialogContent>
                <StyledInput
                  type="text"
                  placeholder="Name eingeben"
                  value={deckName}
                  onChange={(e) => setDeckName(e.target.value)}
                />
                <Button
                  onClick={handleFinalSave}
                  sx={{
                    color: theme.palette.getContrastText(
                      theme.palette.success.main
                    ),
                    backgroundColor: theme.palette.success.main,
                    width: '100%',
                    fontFamily: theme.typography.fontFamily,
                    '&:hover': {
                      backgroundColor: theme.palette.success.dark,
                    },
                  }}
                >
                  Deck Speichern
                </Button>
              </StyledDialogContent>
            </div>
          </Fade>
        </StyledDialog>
      </Box>
    </>
  );
};

/** Styled Components **/
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.action.disabledBackground,
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
}));

const Item = styled(Paper)(({ theme, clickable }) => ({
  backgroundColor: theme.palette.background.paper,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  cursor: clickable ? 'pointer' : 'default',
}));

const TallItem = styled(Item)(({ theme, selected }) => ({
  height: '80px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  padding: theme.spacing(2),
  border: selected
    ? `1px solid ${theme.palette.success.main}`
    : `1px solid ${theme.palette.error.main}`,
  cursor: 'pointer',
}));

const TallItemContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
});

const IconContainer = styled('div')({
  position: 'absolute',
  top: 8,
  left: 8,
  right: 8,
  bottom: 8,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

const CardTitle = styled('div')(({ theme }) => ({
  ...theme.typography.subtitle1,
  fontWeight: theme.typography.fontWeightBold,
}));

const CardCategory = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
}));

const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  fontFamily: theme.typography.fontFamily,
}));

export default Deckbuilding;
