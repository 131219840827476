import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Home as HomeIcon, RecentActors as DecksIcon, Games as SpielenIcon, AccountCircle as ProfileIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'center',
  '& .MuiBottomNavigationAction-root': {
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  },
}));

const MyBottomNavigation = () => {
  const location = useLocation();
  const [value, setValue] = useState(0);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/home')) setValue(0);
    else if (path.includes('/user-decks')) setValue(1);
    else if (path.includes('/spielen')) setValue(2);
    else if (path.includes('/profil')) setValue(3);
    else setValue(-1);
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper 
      sx={{ 
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        right: 0,
        border: 'none', 
        overflow: 'hidden',
        borderRadius: '0%',
        zIndex: 999
      }} 
      elevation={3}
    >
      <StyledBottomNavigation
        value={value}
        onChange={handleChange}
        showLabels
      >
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon />}
          component={Link}
          to="/home"
        />
        <BottomNavigationAction
          label="Decks"
          icon={<DecksIcon />}
          component={Link}
          to="/user-decks"
        />
        <BottomNavigationAction
          label="Spielen"
          icon={<SpielenIcon />}
          component={Link}
          to="/spielen"
        />
        <BottomNavigationAction
          label="Profil"
          icon={<ProfileIcon />}
          component={Link}
          to="/profil"
        />
      </StyledBottomNavigation>
    </Paper>
  );
};

export default MyBottomNavigation;
