import { createTheme } from '@mui/material/styles';

const getCustomTheme = (mode) => {
  const primaryColor = {
    light: '#42A5F5',
    dark: '#90CAF9',
  };

  const secondaryColor = '#654321';

  return createTheme({
    palette: {
      mode,
      primary: {
        main: primaryColor[mode],
      },
      secondary: {
        main: secondaryColor,
      },
      ...(mode === 'dark'
        ? {
            background: {
              default: '#000000',
              paper: '#212121',
            },
            text: {
              primary: '#ffffff',
            },
          }
        : {
            background: {
              default: '#ffffff',
              paper: '#f0f0f0',
            },
            text: {
              primary: '#000000',
            },
          }),
    },
    typography: {
      fontFamily: 'monospace, serif',
      fontSize: 14,
      h5: {
        color: primaryColor[mode],
        textAlign: 'left',
        marginBottom: '0.3em',
      },
      h6: {
        color: mode === 'dark' ? '#ffffff' : '#000000',
        textAlign: 'left',
        paddingTop: '1em',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '4px',
            border: '1px solid',
            borderColor: mode === 'dark' ? '#ffffff' : '#000000',
            color: mode === 'dark' ? '#ffffff' : '#000000',
            '&:hover': {
              backgroundColor: mode === 'dark' ? '#4a4a4a' : '#e0e0e0',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#212121' : '#ffffff',
            color: mode === 'dark' ? '#ffffff' : '#000000',
            border: '1px solid',
            borderColor: mode === 'dark' ? '#ffffff' : '#000000',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: mode === 'dark' ? '#ffffff' : '#0000ee',
            textDecoration: 'underline',
            '&:hover': {
              color:
                mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.7)'
                  : 'rgba(0, 0, 238, 0.7)',
            },
          },
        },
      },
    },
  });
};

export default getCustomTheme;
