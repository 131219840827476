import React from 'react';
import { Box, Typography, Divider, Switch, Paper } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AdminPanelSettings as AdminIcon } from '@mui/icons-material';
import CustomizableHeader from '../components/common/CustomizeableHeader';
import { useAuth } from '../contexts/AuthContext';
import { useThemeContext } from '../contexts/ThemeContext';
import ActionButton from '../components/common/ActionButton';
import { Link } from 'react-router-dom';

const Profil = () => {
  const { user, logout } = useAuth();
  const { mode, toggleMode } = useThemeContext(); // Access mode and toggleMode from ThemeContext

  return (
    <>
      <CustomizableHeader
        leftIcon={<AccountCircleIcon />}
        leftText="Profil"
        rightContent={
          user && user.is_admin ? (
            <Link to="/admin" style={{ textDecoration: 'none', color: 'inherit' }}>
              <AdminIcon />
            </Link>
          ) : null
        }
      />
      <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h5">Hi, {user ? user.username : 'Guest'}!</Typography>
          <Typography>Schön, dass du da bist.</Typography>

          <Divider sx={{ my: 2 }} />

          <Typography variant="h6">Einstellungen</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
            <Typography>Dark Mode</Typography>
            <Switch checked={mode === 'dark'} onChange={toggleMode} />
          </Box>

          <Divider sx={{ my: 2 }} />

          {!user && (
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <ActionButton action="submit" color="primary" component={Link} to="/anmelden">
                Anmelden
              </ActionButton>
              <ActionButton color="secondary" component={Link} to="/registrieren">
                Registrieren
              </ActionButton>
            </Box>
          )}

          <Paper sx={{ padding: 2, mt: 3, borderRadius: 2, bgcolor: 'background.paper', color: 'text.primary' }}>
            <Typography variant="subtitle1" gutterBottom>
              Nützliche Links:
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Link to="/anleitung" style={{ textDecoration: 'none', color: 'gray' }}>
                <Typography variant="body2">Anleitung</Typography>
              </Link>
              <Link to="/ueber-uns" style={{ textDecoration: 'none', color: 'gray' }}>
                <Typography variant="body2">Über uns</Typography>
              </Link>
              <Link to="/datenschutz" style={{ textDecoration: 'none', color: 'gray' }}>
                <Typography variant="body2">Datenschutzerklärung</Typography>
              </Link>
              <Link to="/impressum" style={{ textDecoration: 'none', color: 'gray' }}>
                <Typography variant="body2">Impressum</Typography>
              </Link>
            </Box>
          </Paper>
        </Box>

        {/* Logout Button at the Bottom */}
        {user && (
          <ActionButton
            action="cancel"
            color="secondary"
            onClick={logout}
            sx={{ mt: 3, alignSelf: 'center' }}
          >
            Abmelden
          </ActionButton>
        )}
      </Box>
    </>
  );
};

export default Profil;
