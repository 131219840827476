import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import NewsIcon from "@mui/icons-material/Announcement";
import { Link } from "react-router-dom";
import { useNewsService } from "./../../services/newsService";
import CustomStyledTextField from "../../components/common/CustomStyledTextField";
import ActionButton from "../../components/common/ActionButton";
import NewsContent from "../../components/common/NewsContent";
import CustomizableHeader from "../../components/common/CustomizeableHeader";

const AdminNews = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [news, setNews] = useState([]);

  const newsService = useNewsService();

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await newsService.getNews();
      setNews(response.data.news);
    } catch (error) {
      // TODO: add error alert
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await newsService.createNews({ title, content });
      setTitle("");
      setContent("");
      fetchNews();
    } catch (error) {
      // TODO: add error alert
    }
  };

  const handleDelete = async (id) => {
    try {
      await newsService.deleteNews(id);
      fetchNews();
    } catch (error) {
      // TODO: add error alert
    }
  };

  return (
    <>
      <CustomizableHeader
        leftIcon={<NewsIcon />}
        leftText="News"
        rightContent={
          <Link to="/admin">
            <IconButton>
              <AdminPanelSettingsIcon />
            </IconButton>
          </Link>
        }
      />
      <Box sx={{ flexGrow: 1, padding: 3, minHeight: "90vh" }}>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                borderRadius: "4px",
                padding: 2,
                backgroundColor: "background.paper",
                color: "text.primary",
                marginBottom: 2,
              }}
            >
              <Typography variant="h5">Neue News 📰</Typography>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  alignItems: "center",
                }}
              >
                <CustomStyledTextField
                  label="Titel"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  fullWidth
                />
                <CustomStyledTextField
                  label="Inhalt"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  required
                  fullWidth
                  multiline
                  rows={2}
                />
                <ActionButton action="save" onClick={handleSubmit} color="primary">
                  Veröffentlichen
                </ActionButton>
              </form>

              <Divider sx={{ my: 2 }} />

              <Typography variant="h5">News verwalten ✏️</Typography>
              <NewsContent
                news={news}
                showDeleteButton={true}
                onDelete={handleDelete}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AdminNews;
