import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LoginIcon from '@mui/icons-material/Login';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: theme.spacing(0.5, 1),
  textTransform: 'none',
  '& .MuiButton-startIcon': {
    marginRight: 2,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 16,
  }
}));

const ActionButton = ({ action, onClick, color = 'primary', children, ...props }) => {
  const icons = {
    edit: <EditIcon />,
    delete: <DeleteIcon />,
    save: <SaveIcon />,
    cancel: <CancelIcon />,
    show: <VisibilityIcon />,
    hide: <VisibilityOffIcon />,
    create: <AddBoxIcon />,
    submit: <LoginIcon />
  };

  const translations = {
    edit: 'Bearbeiten',
    delete: 'Löschen',
    save: 'Speichern', 
    cancel: 'Abbrechen',
    show: 'Anzeigen',
    hide: 'Ausblenden',
    create: 'Erstellen',
    submit: 'Anmelden'
  };

  const icon = action ? icons[action] : null;
  const text = children || (action ? translations[action] : '');

  return (
    <StyledButton
      startIcon={icon}
      onClick={onClick}
      color={color}
      {...props}
    >
      {text}
    </StyledButton>
  );
};

export default ActionButton;