import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDeckService } from '../../services/deckService';
import { useAdminDeckService } from '../../services/adminDeckService';
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  CircularProgress,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  useTheme,
} from '@mui/material';
import FullCardItem from "../../components/cards/FullCardItem";
import Item from "../../components/common/Item";
import ActionButton from "../../components/common/ActionButton";
import LoginPrompt from "../../components/decks/LoginPromt";
import { useAuth } from "../../contexts/AuthContext";
import InfoHint from "../../components/common/InfoHint";

const Decks = () => (
  <Box
    sx={{
      p: 2,
      overflowY: 'auto',
      overflowX: 'hidden',
    }}
  >
    <BasicGrid />
  </Box>
);

const BasicGrid = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const deckService = useDeckService();
  const adminDeckService = useAdminDeckService();
  const { user } = useAuth();
  const [decks, setDecks] = useState([]);
  const [adminDecks, setAdminDecks] = useState([]);
  const [selectedDeckIndex, setSelectedDeckIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDecks();
    fetchAdminDecks();
  }, []);

  const fetchDecks = async () => {
    try {
      setLoading(true);
      const response = await deckService.getDecks();
      setDecks(response.data.decks);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchAdminDecks = async () => {
    try {
      const response = await adminDeckService.getAdminDecks();
      setAdminDecks(response.data.admin_decks);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    }
  };

  const handleSelectAdminDeck = async (deck) => {
    try {
      const cardIds = deck.cards.map((card) => card.id);
      await deckService.createDeck(cardIds, deck.name);
      await fetchDecks();
    } catch (error) {
      console.error("Error creating deck from admin deck:", error);
      setError(error.response?.data?.message || error.message);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedDeckIndex(newValue);
  };

  const handleCreateNewDeck = () => {
    navigate('/user-decks/bauen');
  };

  const handleEditDeck = () => {
    navigate('/user-decks/bauen', { state: { deck: decks[selectedDeckIndex] } });
  };

  const handleDeleteDeck = async () => {
    if (window.confirm('Sind Sie sicher, dass Sie dieses Deck löschen möchten?')) {
      try {
        await deckService.deleteDeck(decks[selectedDeckIndex].id);
        await fetchDecks();
      } catch (error) {
        setError(error.response?.data?.message || error.message);
      }
    }
  };

  const calculateStats = (cards) => {
    if (!cards || cards.length === 0) return { avgBlocker: 0, dominantCategory: 'N/A' };

    const totalBlocker = cards.reduce((sum, card) => sum + card.blocker, 0);
    const avgBlocker = (totalBlocker / cards.length).toFixed(2);

    const categoryCount = cards.reduce((acc, card) => {
      acc[card.category] = (acc[card.category] || 0) + 1;
      return acc;
    }, {});

    const dominantCategory = Object.entries(categoryCount).reduce((a, b) => (a[1] > b[1] ? a : b))[0];

    return { avgBlocker, dominantCategory };
  };

  const renderCards = (cards) => (
    <Grid container spacing={2}>
      {cards.map((card, index) => (
        <Grid item xs={12} key={index}>
          <FullCardItem
            card={card}
          />
        </Grid>
      ))}
    </Grid>
  );
  

  if (!user) {
    return <LoginPrompt />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Fehler: {error}</div>;
  }

  const selectedDeck = decks[selectedDeckIndex] || null;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: theme.zIndex.appBar,
        }}
      >
        <Tabs
          value={selectedDeckIndex}
          onChange={handleTabChange}
          aria-label="deck selection tabs"
          sx={{ mb: theme.spacing(2) }}
          variant="fullWidth"
        >
          <Tab label="Deck 1" sx={{ textTransform: 'none' }} />
          <Tab label="Deck 2" sx={{ textTransform: 'none' }} />
          <Tab label="Deck 3" sx={{ textTransform: 'none' }} />
          <Tab label="Deck 4" sx={{ textTransform: 'none' }} />
        </Tabs>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          pb: theme.spacing(8),
          pt: theme.spacing(6),
        }}
      >
        {selectedDeck ? (
          <>
            <DeckName>{selectedDeck.name}</DeckName>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                mb: theme.spacing(2),
              }}
            >
              <ActionButton action="edit" onClick={handleEditDeck} />
              <ActionButton action="delete" onClick={handleDeleteDeck} />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Item clickable>
                  <StatTitle>Statistiken</StatTitle>
                  <StatInfo>
                    Durchschnittlicher Blocker: {calculateStats(selectedDeck.cards).avgBlocker}
                  </StatInfo>
                  <StatInfo>
                    Deck Fokus: {calculateStats(selectedDeck.cards).dominantCategory}
                  </StatInfo>
                </Item>
              </Grid>
              <Divider
                sx={{
                  backgroundColor: theme.palette.divider,
                  height: '1px',
                  width: '95%',
                  m: theme.spacing(1, 'auto'),
                }}
              />
            </Grid>
            {renderCards(selectedDeck.cards)}
          </>
        ) : (
          <Box sx={{ textAlign: 'center', mt: theme.spacing(2) }}>
            <InfoHint>Dieser Deckplatz ist frei</InfoHint>
            <ActionButton
              action="create"
              onClick={handleCreateNewDeck}
              color="primary"
            >
              Neues Deck erstellen
            </ActionButton>
            <Typography variant="h5"
              sx={{ 
                pt: 7,
                mb: 1, 
                pb: 0
            }}>
              Vorgeschlagene Decks:
            </Typography>
            <List>
              {adminDecks.map((deck) => (
                <ListItem key={deck.id} divider>
                  <ListItemText
                    primary={deck.name}
                    secondary={deck.description}
                  />
                  <ActionButton
                    onClick={() => handleSelectAdminDeck(deck)}
                  >
                    Wählen
                  </ActionButton>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </>
  );
};

const DeckName = styled('div')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.h5.fontSize,
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}));

const StatTitle = styled('div')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.subtitle1.fontSize,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StatInfo = styled('div')(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}));

export default Decks;
