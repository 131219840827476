import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { CssBaseline, Box } from '@mui/material';
import { CustomThemeProvider } from './contexts/ThemeContext';

// public routes
import About from './pages/legal/UeberUns';
import Home from './pages/Home';
import Register from './pages/authentification/Register';
import Login from './pages/authentification/Login';
import Anleitung from './pages/legal/Anleitung';
import MyBottomNavigation from './components/BottomNavigation';
import Profil from './pages/Profil';
import PrivacyPolicy from './pages/legal/Datenschutz';
import Imprint from './pages/legal/Impressum';
import Testen from './pages/playing/Testen';
import Spielen from './pages/playing/Spielen';
import Deckbuilding from './pages/decks/Deckbuilding';
import Decks from './pages/decks/Decks';

// admin routes
import AdminRoute from './AdminRoute';
import AdminPortal from './pages/admin/AdminPortal';
import AdminUsers from './pages/admin/AdminUsers';
import AdminCards from './pages/admin/AdminCards';
import AdminDecksManager from './pages/admin/AdminDecksManager';
import AdminDecksCreate from './pages/admin/AdminDecksCreate';
import AdminGameHistory from './pages/admin/AdminGameHistory';
import AdminNews from './pages/admin/AdminNews';

// external links
import ExternalLink from './services/ExternalLink';
import { AuthProvider } from './contexts/AuthContext';

function App() {
  const location = useLocation();
  const noBottomNavPaths = ['/anmelden', '/registrieren', '/testen'];
  const showBottomNav = !noBottomNavPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  return (
    <CustomThemeProvider>
      <CssBaseline />
      <AuthProvider>
        <Box sx={{ paddingBottom: showBottomNav ? '56px' : '0px' }}>
          <Routes>
            {/* Public Routes */}
            <Route path="/ueber-uns" element={<About />} />
            <Route path="/anmelden" element={<Login />} />
            <Route path="/registrieren" element={<Register />} />
            <Route path="/home" element={<Home />} />
            <Route path="/testen/:deckId" element={<Testen />} />
            <Route path="/anleitung" element={<Anleitung />} />
            <Route path="/datenschutz" element={<PrivacyPolicy />} />
            <Route path="/impressum" element={<Imprint />} />
            <Route path="/spielen" element={<Spielen />} />
            <Route path="/user-decks" element={<Decks />} />
            <Route path="/user-decks/bauen" element={<Deckbuilding />} />
            <Route path="/profil" element={<Profil />} />

            {/* Admin Routes */}
            <Route
              path="/admin"
              element={
                <AdminRoute>
                  <AdminPortal />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <AdminRoute>
                  <AdminUsers />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/karten"
              element={
                <AdminRoute>
                  <AdminCards />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/decks"
              element={
                <AdminRoute>
                  <AdminDecksManager />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/decks/bauen"
              element={
                <AdminRoute>
                  <AdminDecksCreate />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/news"
              element={
                <AdminRoute>
                  <AdminNews />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/spielhistorie"
              element={
                <AdminRoute>
                  <AdminGameHistory />
                </AdminRoute>
              }
            />

            {/* External Links */}
            <Route path="/external-link" element={<ExternalLink />} />

            {/* Catch-All Route */}
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
        </Box>
        {showBottomNav && <MyBottomNavigation />}
      </AuthProvider>
    </CustomThemeProvider>
  );
}

export default App;
