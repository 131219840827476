import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, Box, Card, CardContent, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import DeckSelectionStep from './DeckSelectionStep';
import InfoHint from '../common/InfoHint';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const GameStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDeckId, setSelectedDeckId] = useState(null);
  const [selectedDeckName, setSelectedDeckName] = useState(null); // State for deck name
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  // Define steps based on user's authentication status
  const steps = user
    ? ['Deck wählen', 'Gegner finden', 'Spiel starten']
    : ['Deck wählen', 'Testen'];

  const handleNext = () => {
    if (activeStep === 0 && selectedDeckId) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handleReset = () => {
    setActiveStep(0);
    setSelectedDeckId(null);
    setSelectedDeckName(null); // Reset deck name
  };

  const handleDeckSelect = (deckId, deckName) => {
    setSelectedDeckId(deckId);
    setSelectedDeckName(deckName); // Set selected deck name
  };

  const handleStartTest = () => {
    if (selectedDeckId) {
      navigate(`/testen/${selectedDeckId}`);
    }
  };

  // Render content based on step and user status
  const getStepContent = (step) => {
    if (!user) {
      // Not logged-in steps
      switch (step) {
        case 0:
          return <DeckSelectionStep onSelect={handleDeckSelect} />;
        case 1:
          return (
            <Box sx={{ textAlign: 'center' }}>
              <Card
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  padding: 2,
                  display: 'inline-block',
                  position: 'relative',
                }}
              >
                <CardContent>
                  <Typography variant="body1">{selectedDeckName}</Typography>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: theme.palette.primary.contrastText,
                    }}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </CardContent>
              </Card>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleStartTest}
                >
                  Test beginnen
                </Button>
              </Box>
            </Box>
          );
        default:
          return 'Unbekannter Schritt';
      }
    } else {
      // Logged-in steps
      switch (step) {
        case 0:
          return <DeckSelectionStep onSelect={handleDeckSelect} />;
        case 1:
          return (
            <Box sx={{ textAlign: 'center' }}>
              <InfoHint message="Gegner Matching und Live-Spielen werden noch implementiert. Sie können das Spiel jedoch testen." />
              <Card
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  padding: 2,
                  display: 'inline-block',
                  mt: 2,
                  position: 'relative',
                }}
              >
                <CardContent>
                  <Typography variant="body1">{selectedDeckName}</Typography>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: theme.palette.primary.contrastText,
                    }}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </CardContent>
              </Card>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleStartTest}
                >
                  Testen
                </Button>
              </Box>
            </Box>
          );
        case 2:
          return (
            <Typography variant="h6" align="center">
              Bereit, das Spiel zu starten!
            </Typography>
          );
        default:
          return 'Unbekannter Schritt';
      }
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        {activeStep === steps.length ? (
          <>
            <Typography variant="h6" align="center">
              Alle Schritte abgeschlossen - Viel Erfolg beim Spiel!
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ mb: 2 }}>{getStepContent(activeStep)}</Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              {activeStep > 0 && (
                <Button variant="outlined" onClick={handleBack}>
                  Zurück
                </Button>
              )}
              {activeStep < steps.length - 1 && (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={activeStep === 1 && user} // Disable "Weiter" on step 1 for logged-in users
                >
                  {activeStep === steps.length - 1 ? 'Spiel starten' : 'Weiter'}
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default GameStepper;
