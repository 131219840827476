import React, { useState, useEffect } from "react";
import { useLocation, useNavigate  } from 'react-router-dom';
import { useCardService } from '../../services/cardService';
import { useAdminDeckService } from "../../services/adminDeckService";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FilterAltSharpIcon from '@mui/icons-material/FilterAltSharp';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import ActionButton from "../../components/common/ActionButton";
import { Typography } from "@mui/material";
import { RecentActors as DecksIcon } from "@mui/icons-material";
import CustomizableHeader from "../../components/common/CustomizeableHeader";

const TRANSITION_DURATION = 300; // 300ms for both in and out transitions

const StyledDialogTitle = styled(DialogTitle)({
    fontFamily: 'monospace, serif',
    color: 'white',
    backgroundColor: '#212121',
  });
  
  const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      backgroundColor: '#212121',
      color: 'white',
      border: '1px solid white',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  }));
  
  const StyledDialogContent = styled(DialogContent)({
    fontFamily: 'monospace, serif',
    color: 'white',
  });
  
  const Item = styled(Paper)(({ theme, clickable }) => ({
    backgroundColor: '#212121',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: 'white',
    border: '1px solid white',
    fontFamily: 'monospace, serif',
    fontSize: '16px',
    cursor: clickable ? 'pointer' : 'default',
  }));
  
  const TallItem = styled(Item)(({ selected }) => ({
    height: '80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    padding: '16px',
    border: selected ? '1px solid #28a745' : '1px solid #dc3545',
    cursor: 'pointer',
  }));
  
  const TallItemContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  });
  
  const IconContainer = styled('div')({
    position: 'absolute',
    top: 8,
    left: 8,
    right: 8,
    bottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  });
  
  const CardTitle = styled('div')({
    fontWeight: 'bold',
    fontSize: '14px',
  });
  
  const CardCategory = styled('div')({
    fontSize: '12px',
  });
  
  const StyledInput = styled('input')({
    width: '100%',
    padding: '10px',
    marginBottom: '20px',
    backgroundColor: '#333',
    color: 'white',
    border: '1px solid white',
    fontFamily: 'monospace, serif',
  });
  
  const CardCounter = styled(Box)(({ theme, isValid }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#212121',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
    fontFamily: 'monospace, serif',
    fontSize: '18px',
    borderTop: `2px solid ${isValid ? '#28a745' : '#dc3545'}`,
    zIndex: 1001,
  }));

const AdminDecksCreate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editDeck = location.state?.editDeck;
  const cardService = useCardService();
  const adminDeckService = useAdminDeckService(); // New admin service
  const [cardData, setCardData] = useState([]);
  const [filteredCardData, setFilteredCardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedCards, setSelectedCards] = useState({});
  const [selectedCardCount, setSelectedCardCount] = useState(0);
  const [cardDialogOpen, setCardDialogOpen] = useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [deckName, setDeckName] = useState('');
  const [deckDescription, setDeckDescription] = useState(''); // New state for deck description

  useEffect(() => {
    if (editDeck) {
      setDeckName(editDeck.name);
      setDeckDescription(editDeck.description);
      const editSelectedCards = {};
      editDeck.cards.forEach(card => {
        editSelectedCards[card.id] = true;
      });
      setSelectedCards(editSelectedCards);
      setSelectedCardCount(editDeck.cards.length);
    }
    setLoading(false);  // Add this line
  }, [editDeck]);

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const response = await cardService.getCards();
        setCardData(response.data.cards);
        setFilteredCardData(response.data.cards);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching card data:', error);
        setError(error.message);
        setLoading(false);
      }
    };
  
    fetchCardData();
  }, []);

  const handleFinalSave = async () => {
    if (!deckName.trim() || !deckDescription.trim()) {
      alert("Please enter a deck name and description.");
      return;
    }
  
    const selectedCardIds = Object.keys(selectedCards).filter(id => selectedCards[id]);
  
    try {
      let response;
      if (editDeck) {
        response = await adminDeckService.updateAdminDeck(editDeck.id, deckName, deckDescription, selectedCardIds);
      } else {
        response = await adminDeckService.createAdminDeck(deckName, deckDescription, selectedCardIds);
      }
      const data = response.data;
      alert(data.message);
      setSaveDialogOpen(false);
      setDeckName('');
      setDeckDescription('');
      // Navigate back to the AdminDeckManager after saving
      navigate('/admin/decks');
    } catch (error) {
      console.error('Error saving recommended deck:', error);
      alert("Failed to save recommended deck. Please try again.");
    }
  };

  const handleSaveClick = () => {
    const selectedCardIds = Object.keys(selectedCards).filter(id => selectedCards[id]);
     
    if (selectedCardIds.length !== 20) {
      alert("You must select exactly 20 cards to save your deck.");
      return;
    }
   
    setSaveDialogOpen(true);
  };

  const handleCardDialogOpen = (card) => {
    setSelectedCard(card);
    setCardDialogOpen(true);
  };

  const handleCardDialogClose = () => {
    setCardDialogOpen(false);
    // Clear the selected card after the fade-out animation
    setTimeout(() => setSelectedCard(null), TRANSITION_DURATION);
  };

  const handleFilterDialogOpen = () => {
    setFilterDialogOpen(true);
  };

  const handleFilterDialogClose = () => {
    setFilterDialogOpen(false);
  };

  const handleSort = (category) => {
    if (activeCategory === category) {
      setFilteredCardData(cardData);
      setActiveCategory(null);
    } else {
      const filtered = cardData.filter(card => card.category.toLowerCase() === category.toLowerCase());
      setFilteredCardData(filtered);
      setActiveCategory(category);
    }
    handleFilterDialogClose();
  };

  const handleCardSelection = (cardId, e) => {
    e.stopPropagation();
    setSelectedCards(prevSelected => {
      const newSelected = {...prevSelected, [cardId]: !prevSelected[cardId]};
      const newCount = Object.values(newSelected).filter(Boolean).length;
      setSelectedCardCount(newCount);
      return newSelected;
    });
  };

  const CardCounterContent = () => (
    <Typography 
      sx={{ 
        color: selectedCardCount === 20 ? '#28a745' : '#dc3545',
        fontFamily: 'monospace, serif',
        fontSize: '18px',
      }}
    >
      {selectedCardCount}/20 Karten
    </Typography>
  );

  const renderCards = (cards) => (
    <Grid container spacing={2}>
      {cards.map((card, index) => (
        <Grid item xs={6} key={index}>
          <TallItem 
            selected={selectedCards[card.id]} 
            onClick={() => handleCardDialogOpen(card)}
          >
            <IconContainer>
              <IconButton
                onClick={(e) => handleCardSelection(card.id, e)}
                sx={{ color: 'white', fontSize: 'x-large', position: 'absolute', right: 0, padding: 0, zIndex: '999' }}
              >
                {selectedCards[card.id] ? (
                  <RemoveCircleSharpIcon sx={{ color: '#dc3545', fontSize: 'x-large' }} />
                ) : (
                  <AddCircleSharpIcon sx={{ color: '#28a745', fontSize: 'x-large' }} />
                )}
              </IconButton>
            </IconContainer>
            <TallItemContent>
              <CardTitle>{card.name}</CardTitle>
              <CardCategory>{card.category.charAt(0).toUpperCase() + card.category.slice(1).toLowerCase()}</CardCategory>
            </TallItemContent>
          </TallItem>
        </Grid>
      ))}
    </Grid>
  );

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <CustomizableHeader
        leftIcon={<DecksIcon />}
        leftText={editDeck ? "Deck bearbeiten" : "Deck erstellen"}
        rightContent={<CardCounterContent />}
      />
      <Box sx={{ 
        flexGrow: 1, 
        padding: '20px',
        paddingBottom: '80px',
        minHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionButton
                action="save"
                onClick={handleSaveClick}
                color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            <Item
              clickable
              onClick={handleFilterDialogOpen}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px 16px',
              }}
            >
              Filter <FilterAltSharpIcon sx={{ marginLeft: 1 }} />
            </Item>
          </Grid>
          <Divider sx={{ backgroundColor: 'white', height: '1px', width: '95%', margin: '8px auto 16px auto', alignItems: 'center' }} />
        </Grid>
    
        {renderCards(filteredCardData)}
    
        <StyledDialog 
          open={cardDialogOpen}
          onClose={handleCardDialogClose}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: TRANSITION_DURATION }}
        >
          <Fade in={cardDialogOpen} timeout={TRANSITION_DURATION}>
            <div>
              <StyledDialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Karteninformation</span>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleCardDialogClose}
                  aria-label="close"
                >
                  <CancelSharpIcon />
                </IconButton>
              </StyledDialogTitle>
              <StyledDialogContent>
                {selectedCard && (
                  <>
                    <div><strong>Effekt:</strong> {selectedCard.effect}</div>
                    <div><strong>Blockierte Züge:</strong> {selectedCard.blocker}</div>
                  </>
                )}
              </StyledDialogContent>
            </div>
          </Fade>
        </StyledDialog>
        <StyledDialog 
          open={filterDialogOpen}
          onClose={handleFilterDialogClose}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: TRANSITION_DURATION }}
        >
          <Fade in={filterDialogOpen} timeout={TRANSITION_DURATION}>
            <div>
              <StyledDialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Filter</span>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleFilterDialogClose}
                  aria-label="close"
                >
                  <CancelSharpIcon />
                </IconButton>
              </StyledDialogTitle>
              <StyledDialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {["Angriff", "Strategie", "Verteidigung", "Hybrid", "Zauber"].map((category) => (
                    <Button
                      key={category}
                      onClick={() => handleSort(category)}
                      sx={{
                        color: 'white',
                        margin: '8px 0',
                        backgroundColor: activeCategory === category ? '#121212' : '#212121',
                        width: '100%',
                        fontFamily: 'monospace, serif',
                        border: '1px solid white',
                        textTransform: 'none'
                      }}
                    >
                      {category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()}
                    </Button>
                  ))}
                </Box>
              </StyledDialogContent>
            </div>
          </Fade>
        </StyledDialog>
        
        <StyledDialog 
          open={saveDialogOpen}
          onClose={() => setSaveDialogOpen(false)}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: TRANSITION_DURATION }}
        >
          <Fade in={saveDialogOpen} timeout={TRANSITION_DURATION}>
            <div>
              <StyledDialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Admin Deck</span>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setSaveDialogOpen(false)}
                  aria-label="close"
                >
                  <CancelSharpIcon />
                </IconButton>
              </StyledDialogTitle>
              <StyledDialogContent>
                <StyledInput
                  type="text"
                  placeholder="Enter deck name"
                  value={deckName}
                  onChange={(e) => setDeckName(e.target.value)}
                />
                <StyledInput
                  type="text"
                  placeholder="Enter deck description"
                  value={deckDescription}
                  onChange={(e) => setDeckDescription(e.target.value)}
                />
                <ActionButton
                action="save"
                onClick={handleFinalSave}
                color="primary"
               >
                  speichern
              </ActionButton>
              </StyledDialogContent>
            </div>
          </Fade>
        </StyledDialog>
      </Box>
    </>
  );
};

export default AdminDecksCreate;