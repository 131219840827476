import React from "react";
import { Box } from '@mui/material';
import ArticleSharpIcon from '@mui/icons-material/ArticleSharp';
import CustomizableHeader from "../../components/common/CustomizeableHeader";
import InfoHint from "../../components/common/InfoHint";

const Anleitung = () => {
  return (
    <>
      <CustomizableHeader
        leftIcon={<ArticleSharpIcon />}
        leftText="Anleitung"
        rightContent={null}
      />
      <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh' }}>
        <InfoHint>
            Die Anleitung wird in Kürze hinzugefügt.
        </InfoHint>
      </Box>
    </>
  );
};

export default Anleitung;