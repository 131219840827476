import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  border: 'none',
}));

const StyledIconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px',
});

const CustomizableHeader = ({ leftIcon, leftText, rightContent }) => {

  const leftContent = (
    <Box display="flex" alignItems="center">
      <StyledIconWrapper>
        {leftIcon}
      </StyledIconWrapper>
      <Typography variant="body-normal" component="div">
        {leftText}
      </Typography>
    </Box>
  );

  return (
    <StyledAppBar position="static">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {leftContent}
        {rightContent}
      </Toolbar>
    </StyledAppBar>
  );
};

export default CustomizableHeader;