import React, { useState, useEffect } from 'react';
import { useCardService } from '../../services/cardService';
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Divider,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  AdminPanelSettings as AdminIcon,
  CreditCard as CreditCardIcon,
} from '@mui/icons-material';
import ActionButton from '../../components/common/ActionButton';
import { Link } from 'react-router-dom';
import CustomizableHeader from '../../components/common/CustomizeableHeader';
import CustomStyledTextField from '../../components/common/CustomStyledTextField';
import { styled, useTheme } from '@mui/material/styles';
import FullCardItem from '../../components/cards/FullCardItem'; // Import FullCardItem

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
}));

const AdminCards = () => {
  const cardService = useCardService();
  const [cards, setCards] = useState([]);
  const [newCard, setNewCard] = useState({
    name: '',
    blocker: '',
    category: '',
    effect: '',
  });
  const [editingCard, setEditingCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showNewCardForm, setShowNewCardForm] = useState(false);
  const [message, setMessage] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    fetchCards();
  }, []);

  useEffect(() => {
    const isValid = Object.values(newCard).every((value) => value !== '');
    setIsFormValid(isValid);
  }, [newCard]);

  const showMessage = (text, severity = 'success') => {
    setMessage({ text, severity });
    setTimeout(() => setMessage(null), 5000);
  };

  const fetchCards = async () => {
    setLoading(true);
    try {
      const response = await cardService.getCards();
      const cards = await response.data.cards;
      setCards(cards);
      showMessage('Karten erfolgreich geladen');
    } catch (err) {
      showMessage(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const createCard = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await cardService.createCard(newCard);
      await fetchCards();
      setNewCard({ name: '', blocker: 0, category: '', effect: '' });
      setShowNewCardForm(false);
      showMessage('Karte erfolgreich erstellt');
    } catch (err) {
      showMessage(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateCard = async () => {
    if (!editingCard) return;
    setLoading(true);
    try {
      const { id, ...cardData } = editingCard;
      await cardService.updateCard(id, cardData);
      await fetchCards();
      setEditingCard(null);
      showMessage('Karte erfolgreich aktualisiert');
    } catch (err) {
      showMessage(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteCard = async (id) => {
    if (!window.confirm('Bist du sicher, dass du die Karte löschen möchtest?'))
      return;
    setLoading(true);
    try {
      await cardService.deleteCard(id);
      setCards((prevCards) => prevCards.filter((card) => card.id !== id));
      showMessage('Karte erfolgreich gelöscht');
    } catch (err) {
      showMessage(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (card) => {
    setEditingCard({ ...card });
  };

  const handleCancelEdit = () => {
    setEditingCard(null);
  };

  const handleEditChange = (e, field) => {
    setEditingCard((prev) => ({
      ...prev,
      [field]:
        field === 'blocker'
          ? Math.max(0, parseInt(e.target.value) || 0)
          : e.target.value,
    }));
  };

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Snackbar
        open={!!message}
        autoHideDuration={5000}
        onClose={() => setMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setMessage(null)}
          severity={message?.severity}
          sx={{ width: '100%' }}
        >
          {message?.text}
        </Alert>
      </Snackbar>

      <CustomizableHeader
        leftIcon={<CreditCardIcon />}
        leftText="Karten"
        rightContent={
          <Link to="/admin">
            <IconButton sx={{ color: theme.palette.primary.contrastText }}>
              <AdminIcon />
            </IconButton>
          </Link>
        }
      />
      <Box sx={{ flexGrow: 1, padding: 3 }}>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Typography variant="h5" gutterBottom>
              Karte erstellen
            </Typography>
            <ActionButton
              action={showNewCardForm ? 'cancel' : 'create'}
              onClick={() => setShowNewCardForm(!showNewCardForm)}
            />
            {showNewCardForm && (
              <Box mt={2}>
                <form
                  onSubmit={createCard}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    width: '100%',
                  }}
                >
                  <CustomStyledTextField
                    value={newCard.name}
                    onChange={(e) =>
                      setNewCard({ ...newCard, name: e.target.value })
                    }
                    label="Name"
                    required
                  />
                  <CustomStyledTextField
                    type="number"
                    value={newCard.blocker}
                    onChange={(e) =>
                      setNewCard({
                        ...newCard,
                        blocker: Math.max(0, parseInt(e.target.value)),
                      })
                    }
                    label="Blocker"
                    required
                  />
                  <CustomStyledTextField
                    value={newCard.category}
                    onChange={(e) =>
                      setNewCard({ ...newCard, category: e.target.value })
                    }
                    label="Kategorie"
                    required
                  />
                  <CustomStyledTextField
                    value={newCard.effect}
                    onChange={(e) =>
                      setNewCard({ ...newCard, effect: e.target.value })
                    }
                    label="Effekt"
                    required
                  />
                  <ActionButton
                    action="save"
                    type="submit"
                    variant="create"
                    disabled={!isFormValid}
                  >
                    Speichern
                  </ActionButton>
                </form>
              </Box>
            )}
          </Grid>
          <Grid xs={12}>
            <Divider sx={{ my: 3, backgroundColor: theme.palette.divider }} />
            <Typography variant="h5" gutterBottom>
              Existierende Karten
            </Typography>
            {cards.length === 0 ? (
              <Typography>Keine Karten gefunden :/</Typography>
            ) : (
              cards.map((card) => (
                <Box key={card.id} sx={{ marginBottom: 2 }}>
                  {editingCard && editingCard.id === card.id ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        border: `1px solid ${theme.palette.divider}`,
                        padding: 2,
                        borderRadius: theme.shape.borderRadius,
                        backgroundColor: theme.palette.background.paper,
                      }}
                    >
                      <CustomStyledTextField
                        value={editingCard.name}
                        onChange={(e) => handleEditChange(e, 'name')}
                        label="Name"
                      />
                      <CustomStyledTextField
                        type="number"
                        value={editingCard.blocker}
                        onChange={(e) => handleEditChange(e, 'blocker')}
                        label="Blocker"
                      />
                      <CustomStyledTextField
                        value={editingCard.category}
                        onChange={(e) => handleEditChange(e, 'category')}
                        label="Kategorie"
                      />
                      <CustomStyledTextField
                        value={editingCard.effect}
                        onChange={(e) => handleEditChange(e, 'effect')}
                        label="Effekt"
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          justifyContent: 'flex-end',
                        }}
                      >
                        <ActionButton action="save" onClick={updateCard} />
                        <ActionButton
                          action="cancel"
                          onClick={handleCancelEdit}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box >
                      <FullCardItem
                        card={card}
                        handleCardClick={() => handleEditClick(card)}
                      />
                      <Box 
                        sx={{ 
                          display: 'grid',
                          gridTemplateColumns: 'repeat(2, auto)',
                          justifyContent: 'center',
                          gap: 1
                        }}
                      >
                        <ActionButton
                          action="edit"
                          onClick={() => handleEditClick(card)}
                        />
                        <ActionButton
                          action="delete"
                          onClick={() => deleteCard(card.id)}
                        />
                      </Box>
                      <Divider sx={{ my: 2 }} />
                    </Box>
                  )}
                </Box>
              ))
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminCards;
