import React from 'react';
import { Box } from '@mui/material';
import CustomizableHeader from '../../components/common/CustomizeableHeader';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import InfoHint from '../../components/common/InfoHint';

const PrivacyPolicy = () => {
  return (
    <>
      <CustomizableHeader
        leftIcon={<PrivacyTipIcon />}
        leftText="Datenschutzerklärung"
        rightContent={null}
      />
      <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh' }}>
        <InfoHint>
          Die Datenschutzerklärung wird in kürze ergänzt.
        </InfoHint>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
