import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import ActionButton from './ActionButton';

const NewsItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.action.hover, // Updated
  borderRadius: theme.shape.borderRadius,
  '&:last-child': {
    marginBottom: 0,
  },
}));

const NewsDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.8rem',
  marginTop: theme.spacing(1),
}));

const NewsContent = ({ news, showDeleteButton = false, onDelete }) => {
  const theme = useTheme(); // Access the theme

  return (
    <>
      {news.map((item, index) => (
        <React.Fragment key={item.id}>
          {index > 0 && (
            <Divider
              sx={{
                my: 2,
                bgcolor: alpha(theme.palette.divider, 0.12), // Updated
              }}
            />
          )}
          <NewsItem>
            <Typography variant="h6" gutterBottom>
              {item.title}
            </Typography>
            <Typography paragraph align="left">
              {item.content}
            </Typography>
            <NewsDate variant="caption">
              Veröffentlicht am: {new Date(item.created_at).toLocaleDateString()}
            </NewsDate>
            {showDeleteButton && (
              <ActionButton action="delete" onClick={() => onDelete(item.id)} />
            )}
          </NewsItem>
        </React.Fragment>
      ))}
    </>
  );
};

export default NewsContent;
