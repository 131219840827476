import React, { createContext, useState, useEffect, useMemo, useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import getCustomTheme from '../styles/customTheme';

const ThemeContext = createContext();

export const CustomThemeProvider = ({ children }) => {
  // Initialize mode from localStorage or default to 'light'
  const [mode, setMode] = useState(() => localStorage.getItem('mode') || 'light');

  useEffect(() => {
    // Save the selected mode in localStorage whenever it changes
    localStorage.setItem('mode', mode);
  }, [mode]);

  const theme = useMemo(() => getCustomTheme(mode), [mode]);

  const toggleMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
