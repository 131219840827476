import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  Divider,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import SuccessBanner from '../components/common/SuccessBanner';
import { useNewsService } from '../services/newsService';
import NewsContent from '../components/common/NewsContent';
import CustomizableHeader from '../components/common/CustomizeableHeader';
import Item from '../components/common/Item';
import Logo from '../components/common/Logo';


const Home = () => {
  const theme = useTheme();
  const { user, isLoading, logout } = useAuth();
  const [showLoginSuccess, setShowLoginSuccess] = useState(false);
  const [news, setNews] = useState([]);
  const newsService = useNewsService();
  const location = useLocation();
  const successMessage = location.state?.successMessage || '';

  const hideLoginSuccess = useCallback(() => {
    setShowLoginSuccess(false);
    sessionStorage.removeItem('loginSuccess');
  }, []);

  useEffect(() => {
    const loginSuccess = sessionStorage.getItem('loginSuccess');
    if (loginSuccess === 'true') {
      setShowLoginSuccess(true);
      const timer = setTimeout(hideLoginSuccess, 5000);
      return () => clearTimeout(timer);
    }
  }, [hideLoginSuccess]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await newsService.getNews();
        setNews(response.data.news);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: theme.spacing(4) }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <CustomizableHeader
        leftIcon={<Logo src="/logo192.png" alt="Logo" size={4}/>}
        leftText="Facettenschach"
      />
      <Box sx={{ flexGrow: 1, minHeight: '90vh' }}>
        {showLoginSuccess && (
          <SuccessBanner
            message={successMessage || 'Erfolgreich angemeldet!'}
          />
        )}

        <Item>
          <Typography variant="h5">Willkommen 🎉</Typography>
          <Typography align="left" sx={{ mb: theme.spacing(2) }}>
            Entdecke eine neue Dimension des Schachs! Baue dein Kartendeck mit
            spannenden Effekten und revolutioniere dein Spiel. Neugierig?{' '}
            <Link to="/anleitung" style={{ textDecoration: 'none' }}>
              <Typography
                component="span"
                color="primary"
                sx={{ textDecoration: 'underline' }}
              >
                Zur Anleitung
              </Typography>
            </Link>
          </Typography>

          {!user && (
            <>
              <Divider sx={{ my: theme.spacing(2) }} />
              <Typography variant="body2">
                <Link to="/anmelden" style={{ textDecoration: 'none' }}>
                  <Typography
                    component="span"
                    color="primary"
                    sx={{ textDecoration: 'underline' }}
                  >
                    Anmelden
                  </Typography>
                </Link>{' '}
                oder{' '}
                <Link to="/registrieren" style={{ textDecoration: 'none' }}>
                  <Typography
                    component="span"
                    color="primary"
                    sx={{ textDecoration: 'underline' }}
                  >
                    Registrieren
                  </Typography>
                </Link>{' '}
                🔐
              </Typography>
            </>
          )}
        </Item>

        {news.length > 0 && (
          <Item>
            <Typography variant="h5">Neuigkeiten 📰</Typography>
            <NewsContent news={news} showToggleButton={false} />
          </Item>
        )}
      </Box>
    </Box>
  );
};

export default Home;
