import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Divider,
  Link,
  useTheme,
} from '@mui/material';
import { useAuthService } from '../../services/authService';
import Logo from '../../components/common/Logo';
import Item from '../../components/common/Item';
import ActionButton from '../../components/common/ActionButton';

const Register = () => {
  const authService = useAuthService();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isUsernameValid, setIsUsernameValid] = useState(true); 
  const navigate = useNavigate();
  const theme = useTheme();

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername);
    setIsUsernameValid(newUsername.trim().length > 0);
    setErrorMessage('');
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsPasswordValid(newPassword.length >= 8);
    setErrorMessage('');
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    if (!isUsernameValid) {
      setErrorMessage('Der Benutzername darf nicht nur aus Leerzeichen bestehen.');
      return;
    }

    if (!isPasswordValid) {
      setErrorMessage('Das Passwort muss mindestens 8 Zeichen lang sein.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      await authService.register(username, password);
      setSuccessMessage('Registrierung erfolgreich! Weiter zum LogIn...');
      setTimeout(() => {
        navigate('/anmelden');
      }, 2000);
    } catch (error) {
      if (error.response) {
        const errorData = error.response.data;
        if (errorData.message && errorData.message.username) {
          setErrorMessage(errorData.message.username[0]);
        } else {
          setErrorMessage(errorData.message || 'Registrierung fehlgeschlagen');
        }
      } else if (error.request) {
        setErrorMessage('Keine Antwort vom Server. Bitte überprüfen Sie Ihre Internetverbindung.');
      } else {
        setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box
        component={RouterLink}
        to="/home"
        sx={{ display: 'flex', alignItems: 'center', mb: 2, textDecoration: 'none', color: 'inherit' }}
      >
        <Logo src="/logo192.png" alt="App Logo" size={3} />
        <Typography variant="body-normal" sx={{ ml: 1 }}>
          Facettenschach
        </Typography>
      </Box>
      
      <Item>
        <Typography variant="h5" component="h1" gutterBottom>
          Registrieren
        </Typography>
        
        <form onSubmit={handleRegister}>
          <TextField
            fullWidth
            margin="normal"
            required
            id="username"
            label="Benutzername"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={handleUsernameChange}
            error={!isUsernameValid}
            helperText={
              !isUsernameValid ? 'Der Benutzername darf nicht nur aus Leerzeichen bestehen.' : ''
            }
            InputLabelProps={{ required: false }}
          />
          
          <TextField
            fullWidth
            margin="normal"
            required
            name="password"
            label="Passwort"
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            error={!isPasswordValid}
            helperText={
              !isPasswordValid ? 'Das Passwort muss mindestens 8 Zeichen lang sein.' : ''
            }
            InputLabelProps={{ required: false }}
          />
          
          <ActionButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {isLoading ? 'Registrierung...' : 'Registrieren'}
          </ActionButton>
        </form>

        {errorMessage && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        )}
        
        {successMessage && (
          <Typography color="success.main" align="center" sx={{ mt: 2 }}>
            {successMessage}
          </Typography>
        )}
        
        <Divider sx={{ my: 2 }} />
        
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          Bereits registriert?{' '}
          <Link
            component={RouterLink}
            to="/anmelden"
            sx={{ color: theme.palette.primary.main }}
          >
            Anmelden
          </Link>
        </Typography>
      </Item>
    </Box>
  );
};

export default Register;
