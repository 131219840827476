import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const InfoHint = ({ message, children, variant = "body2" }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        mb: 2,
      }}
    >
      <InfoIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
      <Typography variant={variant} color="text.secondary" component="span">
        {message}&nbsp;
        {children}
      </Typography>
    </Box>
  );
};

export default InfoHint;
