import React from "react";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import PeopleIcon from '@mui/icons-material/People';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DeckIcon from '@mui/icons-material/ViewModule';
import HistoryIcon from '@mui/icons-material/History';
import NewsIcon from '@mui/icons-material/Announcement';
import Item from '../../components/common/Item';
import CustomizableHeader from "../../components/common/CustomizeableHeader";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1rem',
  marginBottom: '10px',
  color: theme.palette.text.primary, // Use theme color for text
  '&:hover': {
    textDecoration: 'underline',
  },
}));


const AdminPortal = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomizableHeader leftText="Admin Portal" />
      <Box sx={{ padding: '20px' }}>
        <Grid item xs={12}>
          <Item clickable={false}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <StyledLink to="/admin/users">
                <PeopleIcon color="primary" sx={{ marginRight: '8px' }} />Benutzer
              </StyledLink>
              <StyledLink to="/admin/karten">
                <CreditCardIcon color="primary" sx={{ marginRight: '8px' }} /> Karten
              </StyledLink>
              <StyledLink to="/admin/decks">
                <DeckIcon color="primary" sx={{ marginRight: '8px' }} /> Admin-Decks
              </StyledLink>
              <StyledLink to="/admin/spielhistorie">
                <HistoryIcon color="primary" sx={{ marginRight: '8px' }} /> Spielhistorie
              </StyledLink>
              <StyledLink to="/admin/news">
                <NewsIcon color="primary" sx={{ marginRight: '8px' }} /> News
              </StyledLink>
            </div>
          </Item>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminPortal;
