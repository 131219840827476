import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StyledLogo = styled('img')(({ theme, size }) => ({
  width: theme.spacing(size),
  height: theme.spacing(size),
}));

const Logo = ({ src, alt = 'Logo', size = 5 }) => {
  return <StyledLogo src={src} alt={alt} size={size} />;
};

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  size: PropTypes.number,
};

export default Logo;
