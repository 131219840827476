import React from 'react';
import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme, clickable }) => ({
  padding: theme.spacing(2),
  margin: '1em',
  textAlign: 'center',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  fontFamily: 'monospace, serif',
  fontSize: '16px',
  cursor: clickable ? 'pointer' : 'default',
  border: 'none',
}));

const Item = ({ title, children, clickable }) => {
  return (
    <StyledPaper clickable={clickable}>
      {title && (
        <Typography variant="h5" component="h2" gutterBottom>
          {title}
        </Typography>
      )}
      {children}
    </StyledPaper>
  );
};

export default Item;
