import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, Card, CardContent, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';

const DeckSelector = ({ decks, adminDecks, onSelect, user }) => {
  const [selectedDeckId, setSelectedDeckId] = useState(null);
  const theme = useTheme();

  const handleDeckClick = (deck) => {
    setSelectedDeckId(deck.id);
    onSelect(deck.id, deck.name);
  };

  const renderDeck = (deck, isSelected) => (
    <Grid item xs={12} key={deck.id}>
      <Card
        onClick={() => handleDeckClick(deck)}
        sx={{
          cursor: 'pointer',
          backgroundColor: isSelected
            ? theme.palette.primary.main
            : theme.palette.background.paper,
          color: isSelected
            ? theme.palette.primary.contrastText
            : theme.palette.text.primary,
          position: 'relative',
          padding: 2,
          '&:hover': {
            backgroundColor: isSelected
              ? theme.palette.primary.main
              : theme.palette.action.hover,
          },
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body1">{deck.name}</Typography>
          {isSelected && (
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: theme.palette.primary.contrastText,
                pointerEvents: 'none',
              }}
            >
              <CheckCircleIcon />
            </IconButton>
          )}
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <Box sx={{ flexGrow: 1, padding: '20px' }}>
      {user && decks.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom>
            Eigene Decks
          </Typography>
          <Grid container spacing={2}>
            {decks.map((deck) => renderDeck(deck, selectedDeckId === deck.id))}
          </Grid>
        </>
      )}
      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Vorgeschlagene Decks
      </Typography>
      <Grid container spacing={2}>
        {adminDecks.map((deck) => renderDeck(deck, selectedDeckId === deck.id))}
      </Grid>
    </Box>
  );
};

export default DeckSelector;
